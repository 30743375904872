*{
  background-color: rgb(222, 142, 211);
  text-align: center; 
  font-family: 'Times New Roman', Times, serif;
  }
  

/* .material-symbols-outlined {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  width: 200px;
  flex-direction: column;
  align-content: flex-end;
  flex-wrap: wrap;
  border: none;
  } */
li {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  font-size: 18px;
  }
ul {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  padding: 0;
  flex-direction: column;
}
.button{
  cursor: pointer;
  background:transparent;
  border: none;
  color: rgb(15, 14, 14);
}
input{
  border-color: rgb(10, 10, 10);
}
input:hover{
  border-color: #e339d8;
}
.add{
  margin-left: 5px;
  border-color: black;
}
.add:hover{
  border-color: #e339d8;
}










